/* Basic Editor styles */
.tiptap {
  > * + * {
    margin-top: 1em;
  }

  * {
    letter-spacing: -0.125px;

    &:not(a) {
      color: theme('colors.black') !important;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: theme('fontSize.xl');
    line-height: theme('fontSize.3xl[1].lineHeight');
    font-weight: theme('fontWeight.bold');
  }

  p {
    font-size: theme('fontSize.base');
    line-height: theme('fontSize.3xl[1].lineHeight');
    font-weight: theme('fontWeight.light');
  }

  strong {
    font-weight: theme('fontWeight.medium');
  }

  em {
    font-style: italic;
  }

  u {
    text-decoration: underline solid theme('colors.black');
  }

  s {
    text-decoration: line-through solid theme('colors.black');
  }

  mark {
    background-color: theme('colors.warning.DEFAULT');
  }

  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: theme('width.full');
    min-width: theme('width.auto') !important;
    margin: 0;
    overflow: hidden;
    margin-block-start: theme('margin.8');

    col {
      width: theme('width.auto') !important;
    }

    td,
    th {
      position: relative;
      min-width: 1em;
      padding: theme('padding.2');
      border: 1px solid theme('borderColor.DEFAULT');
      border-radius: theme('borderRadius.DEFAULT');
    }

    th {
      background-color: theme('colors.muted.DEFAULT');
      text-align: center;
      font-weight: bold;
    }
  }

  ul,
  ol {
    list-style-type: revert;
    padding-inline: theme('padding.4');
  }

  a {
    color: theme('colors.primary.DEFAULT');
    font-weight: theme('fontWeight.medium');
    text-decoration: underline solid theme('colors.primary.DEFAULT');
    text-underline-offset: theme('space.2');
  }

  img {
    margin-inline: theme('margin.auto');
  }

  pre {
    background: theme('colors.muted.DEFAULT');
    border-radius: theme('borderRadius.DEFAULT');
    padding: theme('padding.3') theme('padding.4');

    code {
      background: none;
      font-size: theme('fontSize.xs');
      line-height: theme('fontSize.xs[1].lineHeight');
      padding: 0;
    }
  }
}
